import archivedate from '../common/archivedate';
import { getConfigForEnvironment } from '../config/env-config';

let authState;
const { oneDdsApi: { baseUrl } } = getConfigForEnvironment();

export function setAuthState(newAuthState) {
  authState = newAuthState;
}

const encodeIDToken = (token) => encodeURIComponent(token);
// URLs and API keys need to be pulled from config file

// later
export function getTripsForEmail(email, searchAfter, size, type, country) {
  const archDate = archivedate();
  const requestData = {
    query: `transactiondatetimeinutc: [${archDate} TO *]`,
    searchAfter,
    type,
    size,
    country,
  };

  console.log(`Fetching trips for email ${email}`);
  console.log(authState, 'authstate');
  // console.log('Encoded', encodeIDToken(JSON.stringify(authState.idToken)));

  return fetch(`https://${baseUrl}/searchdocument`, {
    method: 'POST',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
  });
}
// later

// search invoices <= 1 year old
export function searchTrips(term, size, searchAfter, type, country) {
  console.log('Inside main search term API via text');
  let searchTerm = term;

  if (!searchTerm) searchTerm = '*';

  const archDate = archivedate();
  const queryFormed = `${searchTerm} AND transactiondatetimeinutc: [${archDate} TO *]`;
  console.log(`Query formed: ${queryFormed}`);

  const requestData = {
    query: queryFormed,
    size,
    searchAfter,
    type,
    country,
  };
  console.log(`Sending the page size: ${JSON.stringify(requestData)}`);
  console.log(`Searching for ${queryFormed}`);
  return fetch(`https://${baseUrl}/searchdocument`, {
    method: 'POST',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
  });
}

export function dateRangeSearch(start, end, searchAfter) {
  console.log('inside date range API');

  const archDate = archivedate();
  let queryFormed;

  const a1 = new Date(archDate);
  const a2 = new Date(start.dateString);
  const a3 = new Date(end.dateString);

  console.log(`Archived date: ${archDate}`);
  console.log(`Start date: ${start.dateString}`);
  console.log(`End date: ${end.dateString}`);

  // archive dateTime and enddate dateTime are same
  if ((a1 - a3) === 0) {
    console.log('archive dateTime and enddate dateTime are same');
    queryFormed = `invoice_issue_date: [${end.dateString} TO ${end.dateString}]`;
  }

  // archive dateTime comes later than enddate datetime
  if ((a1 - a3) > 0) {
    console.log('archive dateTime comes later than enddate datetime');
    return Promise.resolve([]);
  }
  // enddate datetime comes later than archive datetime

  console.log('enddate datetime comes later than archive datetime');
  // startdate datetime comes later/same than archived datetime
  if ((a2 - a1) >= 0) {
    console.log('startdate datetime comes later/same than archived datetime');
    queryFormed = `invoice_issue_date: [${start.dateString} TO ${end.dateString}]`;
  } else {
    // startdate datetme comes earlier than archived datetime
    console.log('startdate datetme comes earlier than archived datetime');
    queryFormed = `transactiondatetimeinutc: [${archDate} TO ${end.dateString}]`;
  }

  const requestData = {
    // query: `transactiondatetimeinutc: [${start.dateString} TO${end.dateString}]`,
    query: queryFormed,
    searchAfter,
  };

  console.log(`Searching for ${requestData.query}`);
  return fetch(`https://${baseUrl}/searchdocument`, {
    method: 'POST',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
  });
}

export function downloadDocument(documentsToDownload) {
  console.log(JSON.stringify(documentsToDownload));
  return fetch(`https://${baseUrl}/docget`, {
    method: 'POST',
    body: JSON.stringify(documentsToDownload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
    responseType: 'arraybuffer',
  });
}

export function sendInvoiceEmail(emails, documents) {
  console.log(`Sending invoices to email ${JSON.stringify(emails)}`);
  const request = {
    documents,
    emails,
  };
  return fetch(`https://${baseUrl}/docresend`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
    responseType: 'arraybuffer',
  });
}

export function addAssistantEmail(request) {
  console.log(`Adding assistant email ${JSON.stringify(request)}`);
  return fetch(`https://${baseUrl}/addassistant`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
    responseType: 'arraybuffer',
  });
}

export function readAssistantEmail(request) {
  console.log(`Reading assistant email ${JSON.stringify(request)}`);
  return fetch(`https://${baseUrl}/addassistant`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
    responseType: 'arraybuffer',
  });
}

export function removeAssistantEmail(request) {
  console.log(`Sending email to remove assistant email ${JSON.stringify(request)}`);
  // const request = assistantsToRemove;
  return fetch(`https://${baseUrl}/addassistant`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
    responseType: 'arraybuffer',
  });
}

export function requestAssistantEmail(request) {
  console.log(`Assistant email sending request for access: ${JSON.stringify(request)}`);
  // const request = assistantsToRemove;
  return fetch(`https://${baseUrl}/addassistant`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
    responseType: 'arraybuffer',
  });
}

export function isTopUser() {
  console.log('Is the user a Top user');
  // const request = assistantsToRemove;
  return fetch(`https://${baseUrl}/topuserstatus`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authState.accessToken.accessToken}`,
      idToken: `${encodeIDToken(JSON.stringify(authState.idToken))}`,
      // idToken: `${JSON.stringify(authState.idToken)}`,
    },
    responseType: 'arraybuffer',
  });
}