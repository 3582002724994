/*eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import * as XLSX from 'xlsx/xlsx.mjs';
import clear from '../../images/GBT-Mktg-01-Arrows-Circle-CMYK-1-w.png';
import info from '../../images/GBT-Mktg-03-Question-Mark-Circle-CMYK-2-db-bb.png';
import styles from './TopUserDashboard.module.css';
import DateInput from '../sidebar/DateInput';
import DatePicker from '../sidebar/DatePicker';
import { useGlobalState } from '../../hooks/StateHook';
import { getTripsForEmail, setAuthState, searchTrips, downloadDocument } from '../../apis/OneDDSAPI';
import Loader from '../common/Loader';
import TopUserDocument from './TopUserDocument';
import { setAmplitudeUserId, setAmplitudeUserProperties } from '../../amplitude/Amplitude';
import Pagination from './Pagination';
import Checkbox from './Checkbox';
import CheckOutsideClick from '../common/CheckOutsideClick';
import QuickSearchHelpModal from './QuickSearchHelpModal';
import NavigationBar from '../tabs/NavigationBar';

function TopUserDashboard() {
  const { t, i18n } = useTranslation();

  const { authState } = useOktaAuth();
  const [authPending, setAuthPending] = useState(true);

  const [globalState, setGlobalState] = useGlobalState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateToPick, setDateToPick] = useState('start');
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [typingTimeout, setTypingTimeout] = useState();
  const [downloadingDocuments, setDownloadingDocuments] = useState(false);
  const [searchAfter, setSearchAfter] = useState();
  const [totalDocuments, setTotalDocuments] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pages, setPages] = useState({});
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const availablePageSizes = [25, 50, 100, 200, 500, 1000];
  const [selectedCountry, setSelectedCountry] = useState('*');
  const [quickSearchText, setQuickSearchText] = useState('');
  const [searchType, setSearchType] = useState('AND');
  const [quickSearchHelp, setQuickSearchHelp] = useState(false);
  const [downloadingReportAll, setDownloadingReportAll] = useState(false);

  const downloadImageCDN = selectedDocuments.length > 0
    ? 'https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/download_active@1x.png'
    : 'https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/download_disabled@1x.png';

  const parseJwt = (jwt) => {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    setAuthState(authState);
    setAuthPending(authState.isPending);
  }, [authState]);

  useEffect(() => {
    console.log('Triggering search');
    const query = buildSearchQuery();
    setLoadingDocuments(true);
    setSelectedDocuments([]);
    searchTrips(query, pageSize, searchAfter)
      .then((data) => data.json())
      .then((data) => {
        const convertToTime = (dateTime) => new Date(dateTime).getTime();
        const result = data.documents.sort((a, b) => convertToTime(b.invoiceIssueDate) - convertToTime(a.invoiceIssueDate));
        const pageDictionary = { ...pages.allPages };
        pageDictionary[currentPage] = pages.nextPage;
        setPages({ allPages: pageDictionary, currentPage: pages.nextPage, nextPage: data.search_after });
        setTotalDocuments(data.totalDocuments);
        setDocuments(result);
        setLoadingDocuments(false);
      }).catch((err) => {
        setLoadingDocuments(false);
        console.log(err);
      });
  }, [searchQuery, searchType, pageSize, currentPage]);

  if (authState.isAuthenticated && !globalState.userEmail) {
    // more than 30 mins then route back to okta.
    setAuthState(authState);
    setAuthPending(authState.isPending);
    const decodedToken = parseJwt(authState.accessToken.accessToken);
    setGlobalState({ userEmail: decodedToken.sub });
    setGlobalState({ name: authState.idToken.claims.name });
    setGlobalState({ role: authState.idToken.claims.roles });
    const userData = {
      email: authState.idToken.claims.email,
      name: authState.idToken.claims.name,
      role: authState.idToken.claims.roles,
      country: authState.idToken.claims.country_of_employment,
      clientID: authState.idToken.clientId,
    };
    setAmplitudeUserId(decodedToken.sub);
    setAmplitudeUserProperties(userData);
  }

  const displayDatePicker = (startOrEnd) => {
    setDateToPick(startOrEnd);
    setShowDatePicker(!showDatePicker);
  };

  const selectStartDate = (date) => {
    if (!date) return;
    if (endDate && endDate.timestamp < date.timestamp) {
      setSearchQuery({ ...searchQuery, startDate: date.dateString, endDate: undefined });
      setEndDate(undefined);
    } else {
      setSearchQuery({ ...searchQuery, startDate: date.dateString });
    }
    setStartDate(date);
    setShowDatePicker(false);
  };

  const selectEndDate = (date) => {
    if (!date) return;
    if (startDate && startDate.timestamp > date.timestamp) {
      setSearchQuery({ ...searchQuery, endDate: date.dateString, startDate: undefined });
      setStartDate(undefined);
    } else {
      setSearchQuery({ ...searchQuery, endDate: date.dateString });
    }
    setEndDate(date);
    setShowDatePicker(false);
  };

  const selectDocument = (document) => {
    const tempArray = [].concat(selectedDocuments);
    tempArray.push(document);
    setSelectedDocuments(tempArray);
  };

  const selectAllDocuments = (event) => {
    if (event.target.checked) {
      const tempArray = [].concat(documents);
      setSelectedDocuments(tempArray);
      setSelectAllChecked(true);
    } else {
      setSelectedDocuments([]);
      setSelectAllChecked(false);
    }
  };

  const setCountry = (event) => {
    setSelectedCountry(event.target.value);
    setSearchQuery({ ...searchQuery, country: event.target.value });
  };

  const changePageSize = (event) => {
    const newPageSize = parseInt(event.target.value);
    setPageSize(newPageSize);
    handlePagination(1);
    setSelectAllChecked(false);
  };

  const deselectDocument = (documentToRemove) => {
    let tempArray = [].concat(selectedDocuments);
    tempArray.find((document, index) => {
      if (document.invoicenumber === documentToRemove.invoicenumber && document.pnr === documentToRemove.pnr) {
        delete tempArray[index];
      }
    });
    tempArray = tempArray.filter((item) => item !== null);
    setSelectedDocuments(tempArray);
  };

  const quickSearchUpdate = (event) => {
    setQuickSearchText(event.target.value);
    clearTimeout(typingTimeout);
    setTypingTimeout(setTimeout(() => {
      setSearchQuery({ ...searchQuery, quickSearch: event.target.value });
    }, 1000));
  };

  const isDocumentSelected = (documentToFind) => Boolean(selectedDocuments.find((document) => document.invoicenumber === documentToFind.invoicenumber && document.pnr === documentToFind.pnr));

  const fetchInitialTrips = () => {
    setLoadingDocuments(true);
    getTripsForEmail(globalState.userEmail, searchAfter, pageSize, 'recent')
      .then((response) => response.json())
      .then((data) => {
        const pageDictionary = { ...pages.allPages };
        pageDictionary[currentPage] = pages.nextPage;
        setPages({ allPages: pageDictionary, previousPage: pages.currentPage, currentPage: pages.nextPage, nextPage: data.search_after });
        setTotalDocuments(data.totalDocuments);
        let result = data.documents;
        const convertToTime = (dateTime) => new Date(dateTime).getTime();
        result = result.sort((a, b) => convertToTime(b.invoiceIssueDate) - convertToTime(a.invoiceIssueDate));
        setDocuments(result);
        setLoadingDocuments(false);
      })
      .catch(() => {
        setLoadingDocuments(false);
      });
  };

  useEffect(() => {
    fetchInitialTrips();
  }, []);

  const processBase64Download = (base64Data, fileName, fileType) => {
    const byteCharacters = atob(base64Data.replace(/\s/g, '').replace(/['"]+/g, ''));
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const zip = new Blob([byteArray], { type: fileType });
    const href = window.URL.createObjectURL(zip);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  console.log('Selected Values', selectedDocuments);

  const downloadReport = () => {
    if (selectedDocuments.length > 0) {
      const rows = [];
      rows[0] = `${t('country')},${t('customer_name')},${t('customer_num')},${t('document')},${t('invoice_date')},${t('currency')},${t('total')},${t('GBT_booking_ref')},${t('lead_traveler')},${t('ticket_number')},${t('destination')},${t('short_card_num')},${t('ref1')},${t('ref2')},${t('ref3')},${t('ref4')},${t('ref5')},${t('ref6')},${t('ref7')},${t('ref8')},${t('ref9')},${t('ref10')},${t('trip_date')},${t('order_by')},${t('original_invoice_number')},${t('original_invoice_date')},${t('travel_types')}`.split(',');
      selectedDocuments.forEach((document) => {
      // extracting client references after comma.
        const extractedValues = document.clientRefs ? Object.keys(document.clientRefs).map((key) => {
          const value = document.clientRefs[key];
          if (value === null || undefined) {
            return '';
          }
          return value && value.split(',')[1];
        }) : '';
        console.log('extractedValues', extractedValues);
        const travelerNames = document.travelerName ? [].concat(document.travelerName).join('|') : '';
        const ticketNumber = document.ticketnumbers ? document.ticketnumbers.join('|') : '';
        const shortCardNumber = document.short_card_numbers ? document.short_card_numbers.join('|') : '';

        const clientnames = document.clientname === undefined ? '' : document.clientname.replace(/,/g, '|');
        const destinations = document.destination === undefined ? '' : document.destination.replace(/,/g, '|');
        const departureDate = document.departureDate === undefined ? '' : document.departureDate;
        const orderedBy = document.ordered_by === undefined ? '' : document.ordered_by.replace(/,/g, '|');
        const originalInvoiceNumber = document.original_invoice_number === undefined ? '' : document.original_invoice_number;
        const originalInvoiceDate = document.original_invoice_date === undefined ? '' : document.original_invoice_date;
        const travelTypesTranslate = document.traveltypes.map((type) => (type === 'Rail' ? `${t('rails')}`
          : type === 'Air' ? `${t('air')}` : type === 'Fees' ? `${t('fee')}` : type === 'Car' ? `${t('car')}`
            : type === 'Hotel' ? `${t('hotel')}` : type === 'Boat' ? `${t('boat')}`
              : type === 'Visa' || 'FerryCruise' || 'BusCoachTaxi' || 'TourOperator' || 'CommissionPassBack' || 'Insurance' || 'Other' || 'GiftVoucher' ? `${t('misc')}` : type));
        const distinctTravelTypes = [...new Set(travelTypesTranslate)];
        const travelTypes = distinctTravelTypes ? distinctTravelTypes.join('|') : '';

        rows.push(`${document.countrycode},${clientnames},${document.dknumber},${document.invoicenumber},${document.invoiceIssueDate},${document.currency},${document.totalamount},${document.pnr},${travelerNames},${ticketNumber},${destinations},${shortCardNumber},${extractedValues},${departureDate},${orderedBy},${originalInvoiceNumber},${originalInvoiceDate},${travelTypes}`.split(','));
      });
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(rows, {});
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };
      const wbout = XLSX.write(workbook, wopts);
      const href = window.URL.createObjectURL(new Blob([wbout]));
      const link = document.createElement('a');
      link.href = href;
      const reportDate = new Date();
      link.setAttribute('download', `report-${reportDate.toLocaleDateString()}-${reportDate.getTime()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else return null;
  };

  const buildSearchQuery = () => {
    const searchParts = [];

    if (searchQuery.quickSearch) {
      const exactMatches = [].concat([...searchQuery.quickSearch.matchAll(/"[^"]*"/g)]);
      const singleTerms = [].concat(searchQuery.quickSearch.replaceAll(/"[^"]*"/g, '').split(',')).map((term) => {
        if (term.includes('*')) {
          // Regex queries in ES are case sensitive
          if (term.startsWith('*') && !term.endsWith('*')) {
            return `([.*${term.toUpperCase().substring(1)}])`;
          } if (!term.startsWith('*') && term.endsWith('*')) {
            return `([${term.toUpperCase().substring(0, term.length - 1)}.*])`;
          }
          return `(${term.replaceAll(/\s/g, '\\ ')})`;
        }
        return `"${term}"`;
      });
      const allMatches = exactMatches.concat(singleTerms)
        .filter((term) => Boolean(term))
        .map((term) => term.replaceAll(/\//g, '\\/').replaceAll(/-/g, '\\-').replaceAll(/\[/g, '/').replaceAll(/]/g, '/'));
      if (allMatches.length === 1) {
        searchParts.push(`${allMatches.join(` ${searchType} `)}`);
      } else {
        searchParts.push(`(${allMatches.join(` ${searchType} `)})`);
      }
    }
    if (selectedCountry) {
      searchParts.push(`countrycode:${selectedCountry}`);
    }
    const partialDateSelection = (searchQuery.startDate && !searchQuery.endDate) || (!searchQuery.startDate && searchQuery.endDate);
    if (searchQuery.startDate && searchQuery.endDate) {
      searchParts.push(`invoice_issue_date:[${searchQuery.startDate} TO ${searchQuery.endDate}]`);
    }
    if (searchParts.length === 0 || partialDateSelection) {
      return '';
    }

    if (searchParts.length === 1) {
      return `${searchParts.join(' AND ')}`;
    }
    return `${searchParts.join(' AND ')}`;
  };

  const downloadAllReport = () => {
    const query = buildSearchQuery();
    setDownloadingReportAll(true);
    searchTrips(query, pageSize, undefined, 'report', i18n.language)
      .then((data) => data.text())
      .then((data) => {
        console.log(data, 'data');
        processBase64Download(data, `report-${new Date().toISOString()}.zip`, 'application/zip');
        setDownloadInProgress(false);
        setDownloadingReportAll(false);
      })
      .catch((err) => {
        console.log(err);
        setDownloadingReportAll(false);
      });
  };

  const downloadFiles = () => {
    setDownloadingDocuments(true);
    const downloadRequest = {
      asSignedUrl: true,
    };

    downloadRequest.documents = selectedDocuments.map((document) => ({
      bucketname: document.bucketname,
      bucketkey: document.bucketkey,
      name: `${document.countrycode}_${document.transactiontype ? document.transactiontype.substring(0, 1) : 'NONE'}_${document.invoicenumber}`,
    }));

    if (downloadRequest.documents.length > 1) {
      downloadDocument(downloadRequest)
        .then((res) => res.json())
        .then((data) => {
          const href = data.url;
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', data.fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setDownloadingDocuments(false);
        }).catch((err) => {
          console.log(err);
          setDownloadingDocuments(false);
        });
    } else if (downloadRequest.documents.length === 1) {
      downloadDocument(downloadRequest.documents[0])
        .then((res) => res.text())
        .then((text) => {
          processBase64Download(text, `${downloadRequest.documents[0].name}.pdf`, 'application/pdf');
          setDownloadingDocuments(false);
        }).catch((err) => {
          console.log(err);
          setDownloadingDocuments(false);
        });
    } else setDownloadingDocuments(false);
  };

  const clearSearch = () => {
    console.log('Clearing Search');
    setCountry({ target: { value: '*' } });
    setStartDate(undefined);
    setEndDate(undefined);
    setQuickSearchText('');
    setCurrentPage(1);
    setSearchQuery({ ...searchQuery, startDate: undefined, endDate: undefined, quickSearch: '' });
    setSelectAllChecked(false);
  };

  const documentContents = loadingDocuments
    ? <Loader />
    : (
      <div className={styles.documentContainer}>
        {documents.map((document) => (
          <TopUserDocument selected={isDocumentSelected(document)} document={document} selectDocument={() => selectDocument(document)} deselectDocument={() => deselectDocument(document)} />
        ))}
      </div>
    );

  const handlePagination = (pageNumber) => {
    console.log(`Changing page ${pageNumber} ${currentPage}`);
    if (pageNumber <= 1) {
      setSearchAfter(undefined);
      setCurrentPage(1);
      setSelectAllChecked(false);
    } else if (!(Number(pageNumber) + Number(pageSize) > Number(totalDocuments))) {
      setSearchAfter(pageNumber < currentPage ? pages.allPages[pageNumber] : pages.nextPage);
      setCurrentPage(pageNumber);
      setSelectedDocuments([]);
      setSelectAllChecked(false);
    }
  };

  const lastIndexCaluculation = () => {
    if (currentPage === 1) {
      return Math.min(pageSize, totalDocuments);
    } if (currentPage * pageSize < totalDocuments) {
      return Math.min(pageSize, totalDocuments);
    } if (pageSize > totalDocuments) {
      return totalDocuments;
    } return totalDocuments - (currentPage - 1) * pageSize;
  };

  const handleRadioChange = (e) => {
    setSearchType(e.target.id);
  };

  const handleReportingID = (requiredItems, availableItems) => {
    return requiredItems && requiredItems.every(item => availableItems.includes(item))
  };

  if (downloadInProgress) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader} />
      </div>
    );
  }

  console.log('ReportingID', globalState.reportingID)

  return (
    <div className={styles.container}>
      <NavigationBar />
      <div className={styles.topSpacer}>
        <div className={styles.searchControls}>
          <div className={styles.spacingDiv} />
          <div className={styles.searchOptions}>
            <select onChange={setCountry} value={selectedCountry} className={styles.field} type="select">
              <option value="*" selected={searchQuery.country === '*' || !searchQuery.country}>{t('all_countries')}</option>
              <option value="BE">BE</option>
              <option value="CH">CH</option>
              <option value="DE">DE</option>
              <option value="DK">DK</option>
              <option value="FR">FR</option>
              <option value="GB">GB</option>
              <option value="IE">IE</option>
              <option value="NL">NL</option>
              <option value="NO">NO</option>
              <option value="SE">SE</option>
              <option value="ES">ES</option>
              {handleReportingID(globalState.reportingID,['BWOS','BWOF']) ? <option value="SG">SG</option> : ''}
              {handleReportingID(globalState.reportingID,['BWOS', 'BWOF']) ? <option value="US">US</option> : ''}
            </select>
            <div className={styles.dateSelector}>
              <DateInput date={startDate} standalone clickAction={() => displayDatePicker('start')} label={t('start_date')} />
              <div className={styles.to}>{t('to')}</div>
              <DateInput date={endDate} standalone clickAction={() => displayDatePicker('end')} label={t('end_date')} />
              {showDatePicker ? (
                <CheckOutsideClick styleClass={styles.datePickerContainer} onClickOutside={() => setShowDatePicker(false)}>
                  <DatePicker selectStartDate={selectStartDate} selectEndDate={selectEndDate} dateToSet={dateToPick} start={startDate} end={endDate} />
                </CheckOutsideClick>
              ) : ''}
            </div>
            <div className={styles.quickSearch}>
              <div className={styles.andOrSelector}>
                <div className={styles.row}>
                  <input id="AND" type="radio" name="andOr" defaultChecked onChange={handleRadioChange} />
                  <label htmlFor="and">{t('and')}</label>
                </div>
                <div className={styles.row}>
                  <input id="OR" type="radio" name="andOr" onChange={handleRadioChange} />
                  <label htmlFor="or">{t('or1')}</label>
                </div>
              </div>
              <input onChange={quickSearchUpdate} className={styles.field} type="text" placeholder={t('quick_search')} value={quickSearchText} />
              <img onClick={() => setQuickSearchHelp(true)} className={styles.infoIcon} src={info} alt="info" />
              <img onClick={() => clearSearch()} className={styles.clearIcon} src={clear} alt="clear" />
            </div>
          </div>
          <div className={styles.selectAndDownload}>
            <div className={styles.selectAll}>
              <div className={styles.selectAllText}>
                {t('select_all')}
                {' '}
              </div>
              <Checkbox onCheck={selectAllDocuments} defaultChecked={selectAllChecked} />
              <div className={styles.selectAllBox}>
                {selectedDocuments.length}
                {' '}
                {t('of')}
                {' '}
                {lastIndexCaluculation()}
                {' '}
                {t('selected')}
              </div>
            </div>
            <div className={styles.pageSelectorContainer}>
              {`${t('show')} `}
              <select onChange={changePageSize} className={styles.pageSelector} type="select" value={pageSize}>
                {availablePageSizes.map((psize) => (
                  <option
                    key={psize}
                    value={psize}
                  >
                    {psize}
                  </option>
                ))}
              </select>
              {t('entries')}
            </div>
            <div className={selectedDocuments.length > 0 ? styles.download : styles.downloadDisable}>
              {downloadingDocuments
                ? <Loader />
                : (
                  <div role="button" onClick={() => downloadFiles()}>
                    <div className={styles.downloadButtons}>
                      {t('download_pdf')}
                      <img style={{ marginLeft: '10px' }} src={downloadImageCDN} alt="download" />
                    </div>
                  </div>
                )}
              <div
                role="button"
                onClick={() => downloadReport()}
              >
                <div className={styles.downloadButtons}>
                  {t('report')}
                  <img style={{ marginLeft: '10px' }} src={downloadImageCDN} alt="download" />
                </div>
              </div>
              {
                downloadingReportAll
                  ? <Loader />
                  : (
                    <div
                      role="button"
                      onClick={() => downloadAllReport()}
                      className={styles.reportAll}
                    >
                      <div>
                        {t('report_all')}
                        <img style={{ marginLeft: '10px' }} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/download_active@1x.png" alt="download" />
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </div>
      {documentContents}
      <Pagination
        totalDocuments={totalDocuments}
        handlePagination={handlePagination}
        currentPage={currentPage}
        pageSize={pageSize}
      />
      {quickSearchHelp && <QuickSearchHelpModal closeModal={() => setQuickSearchHelp(false)} />}
    </div>
  );
}

export default TopUserDashboard;
